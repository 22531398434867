import React from "react";
import Head from "../../components/head.js";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Layout from "../../components/layout.js";

const Page = () => {
  return (
    <Layout>
      <Head title="Bernard Joubert" />
      <h3>Bernard Joubert</h3>
      <h4>
        <FaGlobe />
        France
      </h4>

      <p>
        <OutboundLink href="https://bernardjoubert.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.galleriailponte.com/en/bernard-joubert-en/">
          Galleria il Ponte
        </OutboundLink>
      </p>

      <img
        style={{ marginBottom: 5 }}
        src="https://bernardjoubert.com/wp-content/uploads/2020/06/1976_collage-acrylique-sur-journal-le-monde_mardi-4-fevrier-1976_50x67cm.jpg"
        alt="Bernard Joubert Art"
      />
      <p>
        50×67cm • Acrylic on paper. Collage, Le Monde, Tuesday, February 4, 1976
      </p>
    </Layout>
  );
};

export default Page;
